<template>
    <div class="zt-page-content">
        <div class="flex flex-v" style="height: 100%;overflow-y: hidden;">
            <div class="flex-1" style="overflow-y:auto;">
                <div class="content">
                    <div class="zt-block">
                        <div style="padding:20px;">
                            <el-button @click="back()" size="mini" type="primary" plain>返回</el-button>
                        </div>
                    </div>
                    <div class="zt-block">
                        <div class="content-top flex">
                            <div v-if="item.photo_render && item.photo_render.length>0" class="img-carousel">
                                <ItemImages :imgs="item.photo_render" :preUrl="imgUrl" width="400px"></ItemImages>
                            </div>
                            <div class="detail flex-1">
                                <h3 class="item-name ellipsis2">{{item.name}}</h3>
                                <div class="item-series flex">
                                    <div>{{item.style_no}}</div>
                                    <div>{{item.cat_id}}</div>
                                </div>
                                <div v-if="item.design_idea" class="design-idea">
                                    <div>设计理念:</div>
                                    <div class="font14-grey1">{{item.design_idea}}</div>
                                </div>
                                <div v-if="item.type==2" class="item-look flex">
                                    <div class="look-item">
                                        <div>--</div>
                                        <div>累计点击</div>
                                    </div>
                                    <div class="look-item">
                                        <div>--</div>
                                        <div>累计下载</div>
                                    </div>
                                    <div class="look-item">
                                        <div>--</div>
                                        <div>第三方平台上架</div>
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                    <div class="zt-block">
                        <div style="padding:30px 20px;">
                            <div v-if="item.factory && item.factory.id" class="facList">
                                <div style="margin-bottom:20px" class="flex">
                                    <div class="facList-left flex">
                                        <div style="width:160px;height:160px;margin-right:30px;">
                                            <div v-if="item.factory.logo" class="bg_img"
                                            :style="{backgroundImage: 'url(' + imgUrl+item.factory.logo + ')'}"></div>
                                        </div>
                                        <div style="position:relative;max-width:400px" class="flex-1">
                                            <h3 style="color:#4D4D4D;margin-top:5px;" class="item-name ellipsis1">{{item.factory.name}}</h3>
                                            <div class="fac-label flex">
                                                <div v-for="(item,idx) in aptitude.ability_service.fwbq" :key="idx">{{item.v}}</div>
                                            </div>
                                            <div v-if="aptitude && aptitude.real_check && aptitude.real_check.expired_at !=0" style="position:absolute;bottom:0;left:0px">
                                                <span style="margin-right:8px;" class="font14-grey1">
                                                    <i style="font-size:14px;color:#DFA64E;margin-right:8px;" class="iconfont icon-xuanzhongduihao1"></i>已实地认证</span>
                                                <span style="font-size:12px;color:#CCCCCC;">{{'2019/09/04' | moment("YYYY/MM/DD")}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="facList-right flex-1">
                                        <div style="max-height:152px;background:#F5F5F5;padding:20px;" class="flex">
                                            <div style="text-align:center;font-size:16px;line-height:112px;padding:0 20px;" class="fac-r-title ">工艺质量：</div>
                                            <div class="fac-r-list flex-1" style="min-width:192px">
                                                <div v-for="(item,idx) in aptitude.relation.ability_make" :key="idx" class="flex">
                                                    <div style="margin-right:30px">{{item.name}}</div>
                                                    <div v-for="(item,idx) in item.quota" :key="idx"
                                                        style="margin-right:10px;">{{item.name}}</div>
                                                </div>
                                                <div class="">
                                                    <el-button size="small" type="text">
                                                        <span>查看更多</span>
                                                        <i style="font-size:12px;" class="iconfont icon-fanhuitubiao1"></i>
                                                    </el-button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item-plan">
                                    <el-steps :active="item.sign_stat" align-center>
                                        <el-step title="对方已签约" icon="iconfont icon-dggfg1"></el-step>
                                        <el-step title="等待我方签约" icon="iconfont icon-dggfg1"></el-step>
                                        <el-step title="双方已签约" icon="iconfont icon-dggfg1"></el-step>
                                        <el-step title="等待对方付款" icon="iconfont icon-dggfg1"></el-step>
                                        <el-step title="等待收款确认" icon="iconfont icon-dggfg1"></el-step>
                                        <el-step title="交易完成" icon="iconfont icon-dggfg1"></el-step>
                                    </el-steps>
                                </div>
                            </div>
                            <div v-else class="facList">
                                <div class="title flex">
                                    <div class="line flex-1"></div>
                                    <div class="text">暂无合作工厂</div>
                                    <div class="line flex-1"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="zt-block">
                        <div style="width:1110px;margin:0 auto;padding:24px 0;">
                            <div class="tab_menu flex">
                                <div v-for="(item,index) in tabList" :key="index" @click="tab(index)" class="flex menu_item" :class="{'cur':tabIndex == index}">
                                    {{item.name}}
                                </div>
                            </div>
                            <div class="content-box flex">
                                <!-- <div style="height:350px;padding:50px 65px;" class="box-steps">
                                    <el-steps :active="1" direction="vertical">
                                        <el-step title="标高图" icon="iconfont icon-zu14"></el-step>
                                        <el-step title="素材图" icon="iconfont icon-zu14"></el-step>
                                    </el-steps>
                                </div> -->
                                <div class="pic-wrap-left">
                                    <div class="active">
                                        <i></i><span>标高图</span>
                                    </div>
                                    <div class="line"></div>
                                    <div>
                                        <i></i><span>素材图</span>
                                    </div>
                                </div>
                                <div v-if="tabIndex==0">
                                    <div class="content-img">
                                        <div class="ellipsis" style="font-size:22px;color:#808080;padding-top:20px;"
                                            >{{item.name}}</div>
                                        <div v-if="item.size" style="font-size:14px;color:#808080;"
                                            >{{item.size.len}}mm*{{item.size.wlen}}mm*{{item.size.height}}mm</div>
                                        <div style="font-size:18px;color:#808080;padding:20px 0 10px 0;">标高图</div>
                                        <div v-if="item.photo_size && item.photo_size.length>0">
                                            <div v-for="(item,idx) in item.photo_size" :key="idx">
                                                <img :src="(imgUrl || '')+item+'!width_800px'" alt="">
                                            </div>
                                        </div>
                                        <div v-else>
                                            <img src="../../../assets/images/desgin/zwt.png" alt="">
                                        </div>
                                        <div style="font-size:18px;color:#808080;padding:30px 0 20px 0;">素材图</div>
                                        <div v-if="item.photo_story && item.photo_story.length>0">
                                            <div v-for="(item,index) in item.photo_story" :key="index">
                                                <img :src="(imgUrl || '')+item+'!width_800px'" alt="">
                                            </div>
                                        </div>
                                        <div v-else>
                                            <img src="../../../assets/images/desgin/zwt.png" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div v-if="tabIndex>0">
                                    <div v-for="(item,idx) in item.accessory" :key="idx">
                                        <div v-if="tabIndex===(idx+1)" class="content-img">
                                            <div class="ellipsis" style="font-size:22px;color:#808080;padding-top:20px;"
                                                >{{item.name}}</div>
                                                <div v-if="item.size" style="font-size:14px;color:#808080;"
                                                >{{item.size.len}}mm*{{item.size.wlen}}mm*{{item.size.height}}mm</div>
                                            <div style="font-size:18px;color:#808080;padding:20px 0 10px 0;">标高图</div>
                                            <div v-if="item.photo_size && item.photo_size.length>0">
                                                <div v-for="(item,idx) in item.photo_size" :key="idx">
                                                    <img :src="(imgUrl || '')+item+'!width_800px'" alt="">
                                                </div>
                                            </div>
                                            <div v-else>
                                                <img src="../../../assets/images/desgin/zwt.png" alt="">
                                            </div>
                                            <div style="font-size:18px;color:#808080;padding:30px 0 20px 0;">素材图</div>
                                            <div v-if="item.photo_story && item.photo_story.length>0">
                                                <div v-for="(item,index) in item.photo_story" :key="index">
                                                    <img :src="(imgUrl || '')+item+'!width_800px'" alt="">
                                                </div>
                                            </div>
                                            <div v-else>
                                                <img src="../../../assets/images/desgin/zwt.png" alt="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="item &&item.photo_cad" class="content-cad flex">
                                <div>设计CAD文件：</div>
                                <div class="flex-1 flex">
                                    <img src="../../../assets/images/desgin/icon_cad.png" alt="">
                                    <div v-if="item && item.photo_cad" style="padding-top:50px" class="font14-grey1">{{item.photo_cad.length}}份</div>
                                    <div style="padding-top:35px;margin-left: 20px;">
                                        <!-- <el-button @click="download()" size="small" type="primary" plain>下载</el-button> -->
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
            </div>
            <div v-if="item.type==2" class="fixed-bottom">
                <div v-if="item && item.price_percent" style="text-align:center;font-size:32px;color:#EE1818;">
                    合作比例：{{item.price_percent | percentFilt}}%
                </div>
            </div>
            <div v-if="item.type==1" class="fixed-bottom">
                <div class="flex flex-align-center">
                    <div v-if="item.price" style="font-size:32px;color:#EE1818;" class="flex-1 flex flex-align-center">
                        <div>一口价：￥{{item.price | priceFilt}}</div>
                        <el-button v-if="item.trans_stat>=2" @click="lookContract(item)" style="margin-left: 20px;" size="small" type="primary" plain>查看合同</el-button>
                    </div>
                    <div class="flex">
                        <div v-if="item && item.trans_stat==4" style="font-size:24px;color:#2BBAAE;">双方已签约</div>
                        <div v-if="item && item.trans_stat<1" style="font-size:24px;color:#F66F6A;">暂无工厂签约</div>
                        <div v-if="item && item.trans_stat>=2 && item.trans_stat<4" style="margin-left:20px;"><el-button type="primary">待签约</el-button></div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
    import { IMG_URL_PRE } from '@/config';
    import { designPriceId } from '@/service/design';
    import { contractView } from '@/service/contract';
    import ItemImages from '@/components/ItemImages.vue';
    export default {
        components: {
            ItemImages,
        },
        props:['previewData'],
        data() {
            return {
                imgUrl: IMG_URL_PRE,
                item:{},
                factoryInfo:null,
                popVisible: false,
                popTitle: null,
                tabList:[
                    {name:"主作品"},
                ],
                tabIndex:0,
                aptitude:null,
            }
        },
        watch:{
            previewData:{
                handler() {
                    this.data();
                },
                deep: true
            }
        },
        created() {
            this.data();
        },
        mounted() {
            
        },
        methods: {
            back() {
                this.$emit("back");
            },
            data() {
                // this.item = JSON.parse(decodeURI(this.$route.query.item));
                this.item = this.previewData;
                if (this.item.accessory && this.item.accessory.length>0) {
                    this.item.accessory.forEach(e => {
                        this.tabList.push({name:e.name});
                    });
                }
                console.log(this.item);
            },
            tab(index) { // 选项卡
                this.tabIndex = index;
            },
            download() {

            },
            // 查看合同
            lookContract(item) {
                if (item.contract_no) {
                    window.open(contractView(item.contract_no));
                }
            },
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped src="../detail.css"></style>
<style scoped>
    .content {
        /* width: 80%; */
        min-width: 1360px;
        padding:0 30px;
        background: transparent;
        margin: 0 auto;
    }
    .content-top {
        padding: 64px 84px 45px;
    }
    .content-top .detail {
        margin-left: 45px;
        position: relative;
    }
    .item-name {
        font-size: 24px;
        color: #292929;
        line-height: 28px;
        margin: 12px 0;
    }
    .zt-block {
        border-radius: 2px;
    }
    .item-series {
        font-size: 14px;
        color: #808080;
        line-height: 18px;
        margin-bottom: 20px;
    }
    .item-series>div {
        padding: 0 24px;
        border-right: 1px solid #CCCCCC;
    }
    .item-series>div:first-child {
        padding-left: 0;
    }
    .item-series>div:last-child {
        padding-right: 0;
        border-right: none;
    }
    div.design-idea {
        max-width: 650px;
        padding: 8px 16px 16px 16px;
        background: rgba(43,186,174,0.05);
    }
    .design-idea>div:first-child {
        font-size: 16px;
        color: #4D4D4D;
        line-height: 22px;
        margin-bottom: 10px;
    }
    .item-look {
        position: absolute;
        left: 0;
        bottom: 87px;
    }
    .item-look .look-item {
        min-width: 128px;
        height: 70px;
        text-align: center;
        padding: 8px 0;
        border-radius: 4px;
        background:rgba(255,145,0,0.1);
        margin-right: 20px;
    }
    .look-item>div:first-child {
        font-size: 24px;
        color: #F66F6A;
        line-height: 33px;
    }
    .look-item>div:last-child {
        font-size: 16px;
        color: #4D4D4D;
        line-height: 22px;
    }
    /* -------------工厂信息-------------- */
    .facList {
        padding: 20px 30px;
        border:1px solid rgba(204,204,204,1);
        box-shadow:0px 3px 6px rgba(0,0,0,0.16);
        border-radius:6px;
    }
    .facList-left {
        padding-right: 60px;
        border-right: 1px solid #CCCCCC;
    }
    .fac-label {
        max-width: 400px;
    }
    .fac-label>div {
        font-size: 14px;
        color: #016FFF;
        background:rgba(1,111,255,0.2);
        border-radius: 12px;
        padding: 0 16px;
        height: 24px;
        line-height: 24px;
        margin-right: 16px;
        margin-bottom: 6px;
    }
    .fac-label>div:nth-child(4n) {
        margin-right: 0;
    }
    .fac-label>div:nth-child(2),.fac-label>div:nth-child(7) {
        background:rgba(43,186,174,0.2);
        color: #2BBAAE;
    }
    .fac-label>div:nth-child(3),.fac-label>div:nth-child(8) {
        background:rgba(230,162,60,0.2);
        color: #E6A23C;
    }
    .fac-label>div:nth-child(4),.fac-label>div:nth-child(5) {
        background:rgba(246,111,106,0.2);
        color: #F66F6A;
    }
    .facList-right {
        padding-left: 55px;
    }
    .facList-right>div {
        font-size: 14px;
        color: #808080;
        line-height: 20px;
    }
    .fac-r-list>div {
        padding: 4px 0;
    }
    .icon-fanhuitubiao1 {
        display: inline-block;
        transform:rotate(90deg);
        -ms-transform:rotate(90deg); 	/* IE 9 */
        -moz-transform:rotate(90deg); 	/* Firefox */
        -webkit-transform:rotate(90deg); /* Safari 和 Chrome */
        -o-transform:rotate(90deg); 	/* Opera */
    }
    .item-plan {
        max-width: 1000px;
        padding: 25px 0 20px 0;
        background:rgba(219,162,76,0.1);
    }
    .item-plan>>> .el-step__icon.is-icon,.el-step__icon {
        width: unset;
        background:transparent;
    }
    .item-plan>>> .el-step__icon-inner {
        background: #fff;
        border-radius: 50%;
    }
    .title {
        width: 45%;
        margin: 0 auto;
        padding: 20px 0;
    }
    .title .line {
        position: relative;
        top: -25px;
        border-bottom: 1px solid #707070;
    }
    .title .text {
        padding: 0 16px;
        font-weight: 500;
        font-size: 36px;
        color: #CCCCCC;
    }
</style>
<style scoped>
    /* -------中间部分--------- */
    /*----选项卡----*/
    .tab_menu {
        padding: 0 120px;
        height: 90px;
        line-height: 86px;
        background:rgba(230,230,230,1);
        margin-bottom: 30px;
    }
    .tab_menu .menu_item{
        padding: 0 50px;
        height:90px;
        line-height: 86px;
        /* margin-right:50px; */
        cursor: pointer;
        font-size: 20px;
        color: #4D4D4D;
        position: relative;
    }
    .tab_menu .menu_item.cur{
        background: #fff;
    }
    .tab_menu .menu_item.cur::before {
        content: '';
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-top: 4px solid #8362E4;
    }
    /* ------底部------ */
    .fixed-bottom {
        width: 100%;
        min-width: 1360px;
        height: 108px;
        background:rgba(230,230,230,1);
    }
    .fixed-bottom>div {
        padding: 30px 0;
        width: 1260px;
        margin: 0 auto;
    }
</style>
